
    import gsap from 'gsap';
    import { mapState } from 'vuex';

    export default {
        data () {
            return {
                children: [],
            };
        },

        computed: {
            ...mapState(['ui']),

            isPageAnimated () {
                return !['learn-more'].includes(this.ui.pageName);
            },
        },

        methods: {
            beforeEnter (el) {
                if (!this.isPageAnimated) return;

                // eslint-disable-next-line no-confusing-arrow
                this.children = Array.from(el.children).flatMap((child) => (child.children.length === 0) ? child : [child, ...child.children]);
                this.children.forEach((child) => {
                    child.style.opacity = 0;
                    child.y = 50;
                });
                setTimeout(() => true, 500);
            },
            enter (el, done) {
                if (!this.isPageAnimated) return done();

                setTimeout(() => {
                    this.children.forEach((child, index) => {
                        const delay = index * .15;
                        gsap.fromTo(
                            child,
                            {
                                opacity: 0,
                                y: 50,
                            },
                            {
                                opacity: 1,
                                y: 0,
                                duration: 0.5,
                                delay,
                                onComplete: () => {
                                    if (index === this.children.length - 1) {
                                        done();
                                    }
                                },
                            },
                        );
                    });
                }, 500);
            },
            leave (el, done) {
                if (!this.isPageAnimated) return done();

                this.children.forEach((child, index) => {
                    const delay = index * .1;
                    gsap.to(child, {
                        opacity: 0,
                        y: -75,
                        duration: 0.5,
                        delay,
                        onComplete: () => {
                            if (index === this.children.length - 1) {
                                done();
                            }
                        },
                    });
                });
            },
        },
    };
