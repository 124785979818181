
    import OdysseyBetaLogo from '@components/icons/OdysseyBetaLogo.vue';

    export default {
        name: 'TheFooter',

        components: {
            OdysseyBetaLogo,
        },
    };
