
    import { mapGetters } from 'vuex';
    import SirenIcon from './icons/SirenIcon.vue';

    export default {
        components: {
            SirenIcon,
        },
        computed: {
            ...mapGetters({
                inPhase: 'app/inPhase',
            }),
        },
    };
