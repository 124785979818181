
import store from '../store/index';

// Asynchronous component definitions for lazy-loading routes.
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    DuplicateAccountPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/DuplicateAccountPage.vue'
    ),
    LandingPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/LandingPage.vue'
    ),
    ConfirmationPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/ConfirmationPage.vue'
    ),
    RegisterPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/RegisterPage.vue'
    ),
    IneligiblePage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/IneligiblePage.vue'
    ),
    LearnMorePage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/LearnMorePage.vue'
    );

export default [
    { path: '/', redirect: (to) => {
        // Used to send server-side errors to the '/error' page.
        if (store.state.app.defaultRoute) {
            return store.state.app.defaultRoute;
        }

        // Default route for a user we know nothing about.
        return '/landing';
    } },

    { path: '/landing', component: LandingPage, meta: { public: true } },
    { path: '/register', component: RegisterPage, meta: { public: true } },
    { path: '/confirmation',
        component: ConfirmationPage,
        meta: { public: true },
        beforeEnter: async (to, from, next) => {
            next(from.path === '/register');
        } },
    { path: '/ineligible', component: IneligiblePage, meta: { public: true } },
    { path: '/dup_account', component: DuplicateAccountPage, meta: { public: true } },
    { path: '/learnmore', component: LearnMorePage, meta: { public: true } },
];
