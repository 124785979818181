
    import { mapState, mapGetters, mapActions } from 'vuex';
    import TheHeader from '@components/TheHeader.vue';
    import TheFooter from '@components/TheFooter.vue';
    import FadeTransition from '@components/FadeTransition.vue';
    import StarbucksErrorMessages from '@components/StarbucksErrorMessage.vue';
    import openModal, { SessionExpirationModal } from './modals';
    import logClientError from './client-error';
    import webAnalytics from './plugins/WebAnalytics';

    export default {
        name: 'App',

        components: {
            FadeTransition,
            TheHeader,
            TheFooter,
            StarbucksErrorMessages,
        },

        i18nOptions: { namespaces: ['global'] },

        provide: {
            $webAnalytics: webAnalytics,
        },

        computed: {
            ...mapState([
                'app',
                'profile',
                'ui',
            ]),
            ...mapGetters({
                loggedIn: 'profile/loggedIn',
                isSessionExpired: 'profile/isSessionExpired',
            }),

            showFooter () {
                return !['learn-more'].includes(this.ui.pageName);
            },

            showHeader () {
                return !['learn-more'].includes(this.ui.pageName);
            },
        },

        watch: {
            isSessionExpired (value) {
                if (value) {
                    this.openSessionExpirationModal();
                }
            },
        },

        mounted () {
            // Focus the h1 element to force screen readers back to the top after a navigation.
            // This will ensure screen readers will read the page again.
            // https://a11y-guidelines.orange.com/en/articles/single-page-app/#notify-user-of-page-changes
            this.$router.afterEach(() => this.$refs.h1.focus());
        },

        // If a fatal error occurs rendering any of our descendent components, try
        // to navigate to the error page to preserve some UX.
        // https://vuejs.org/v2/api/#errorCaptured
        errorCaptured (err) {
            this.$router.push('/error');
            // Forward fatal Vue component errors, i.e. those that caused us to display
            // the error page here, to './client-error' and its server-side logging.
            logClientError(err, { fatal: true });
        },

        methods: {
            ...mapActions({
                logOut: 'profile/logOut',
            }),

            async openSessionExpirationModal () {
                await openModal(SessionExpirationModal);

                // After the modal is closed.
                this.logOut();
                this.$router.replace('/');
            },
        },
    };
