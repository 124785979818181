// Asynchronous component definitions for lazy-loading routes.
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    LifecyclePage = async () => import(
        /* webpackChunkName: 'lifecycle' */
        '../views/LifecyclePage.vue'
    ),
    PrelaunchPage = async () => import(
        /* webpackChunkName: 'lifecycle' */
        '../views/PrelaunchPage.vue'
    );

export default [
    { path: '/*', component: LifecyclePage, meta: {
        public: true,
        activeInPhase: ['expired'],
    } },
    { path: '/*', component: PrelaunchPage, meta: {
        public: true,
        activeInPhase: ['pre-launch'],
    } },
];
