
import '../vendor/cookie-gate';

import VueRouter from 'vue-router';

import { i18next } from './i18n';
import store from './store/index';

const loadedRoutes = requireAllRoutes();

function requireAllRoutes () {
    // `require.context` allows us to bundle all files in a directory that match a regex.
    // https://webpack.js.org/guides/dependency-management/#requirecontext
    const r = require.context('./routes', /* include subdirectories */ false, /\.js$/);
    return r.keys().map(id => r(id).default)
    // Flatten array.
    .reduce((acc, val) => acc.concat(val), []);
}

// We filter in advance, rather than using a route guard, b/c there's no way in
// `vue-router` for a route to "decline" to match and allow matching to continue :(
const activeRoutes = loadedRoutes.filter(route =>
    // `route.meta` is an object on the route that contains arbitrary additional data
    // to be used by the application. We use it for maint- and phase-based filtering
    // here and `loggedIn` access control below.
    // https://router.vuejs.org/guide/advanced/meta.html
    (!store.state.app.isInMaint || route.meta && route.meta.activeInMaint) &&
    store.getters['app/inPhase'](route.meta && route.meta.activeInPhase || 'live'));

if (process.env.NODE_ENV === 'development') {
    console.log('active routes', activeRoutes);
}

const routes = [
    ...activeRoutes,
    // We're not on this version of vue-router as of this writing, but this
    // seems to be the way you have to do this in our version already:
    // https://next.router.vuejs.org/guide/essentials/dynamic-matching.html#catch-all-404-not-found-route
    { path: '/:catchAll(.*)', redirect: '/' },
];

const router = new VueRouter({
    routes,

    scrollBehavior (to, from, savedPosition) {
        // https://router.vuejs.org/guide/advanced/scroll-behavior.html#async-scrolling
        return { x: 0, y: 0 }; // scroll to top always
    },
});

// https://router.vuejs.org/api/#router-beforeeach
router.beforeEach((to, from, next) => {
    if (
        // If every matched route is allowed to be publicly accessible.
        to.matched.every(record => record.meta.public) ||
        // Or the user is logged in and their session hasn't yet expired.
        store.getters['profile/loggedIn'] &&
        !store.getters['profile/isSessionExpired']
    ) {
        // Continue on.
        return next();
    }

    // Send everything else back to the app root.
    return next('/');
});

export default router;
