
    import BaseModal from './BaseModal';

    export default {
        name: 'TermsModal',

        extends: BaseModal,

        data () {
            return {
                modalName: 'terms',
            };
        },

        i18nOptions: { namespaces: ['terms'] },
    };
