
    import { mapState } from 'vuex';

    export default {
        i18nOptions: { namespaces: ['error'] },

        data () {
            return {
                incompatibleBrowser: false,
            };
        },

        computed: {
            ...mapState(['app', 'profile']),
        },

        mounted () {
            if (navigator.userAgent.includes('MSIE') || navigator.userAgent.includes('Trident/') || navigator.userAgent.includes('SamsungBrowser')) {
                this.incompatibleBrowser = true;

                // this.$urchin.trackEvent(
                //     'Incompatible Browser Error',
                //     'View',
                //     this.profile.id,
                // );
            }

        },
    };
